import React from 'react';
import { Select } from '../../atoms';
import { RangeSelectProps } from './RangeSelect.types';
import { RangeSelectWrapper, SelectContainer } from './RangeSelect.styled';

export const RangeSelect = ({
  disabled = false,
  hasError = false,
  maxValue = '',
  minValue = '',
  onChange,
  minOptionsList = [],
  maxOptionsList = [],
}: RangeSelectProps) => {
  /*
   * We slice the options to ensure we only allow selecting min values smaller or equal to the max and vice versa
   * If the props sent in have max smaller than min or vice versa, we default to the full list of options and allow the
   * user to select any value to fix the filter
   */
  const maxValueIndex = minOptionsList.findIndex(
    (option) => option.value === maxValue,
  );
  const minValueIndex = maxOptionsList.findIndex(
    (option) => option.value === minValue,
  );
  const isMaxValueValid =
    maxValueIndex !== 0 &&
    (minValueIndex === 0 || maxValueIndex >= minValueIndex);
  const isMinValueValid =
    minValueIndex !== 0 &&
    (maxValueIndex === 0 || minValueIndex <= maxValueIndex);

  const minSliceEnd = (() => {
    if (maxValue === '') return minOptionsList.length; // Case 1: Max value is not set, show all results
    if (isMaxValueValid) return maxValueIndex + 1; // Case 2: Valid maxValue, splice to that index
    return minOptionsList.length; // Case 3: Invalid maxValue, show all results in the min select
  })();

  const maxSliceStart = (() => {
    if (minValue === '') return 1; // Case 1: Min value is not set, show all results
    if (isMinValueValid) return minValueIndex; // Case 2: Valid minValue, splice from that index
    return 1; // Case 3: Invalid minValue, show all results
  })();

  const minOptions = [
    ...minOptionsList.slice(0, minSliceEnd).map((option) => (
      <Select.Option key={option.value} value={option.value}>
        {option.displayName ?? option.value}
      </Select.Option>
    )),
  ];

  const maxOptions = [
    <Select.Option key="max" value={maxOptionsList[0].value}>
      {maxOptionsList[0].displayName ?? maxOptionsList[0].value}
    </Select.Option>,
    ...maxOptionsList.slice(maxSliceStart).map((option) => (
      <Select.Option key={option.value} value={option.value}>
        {option.displayName ?? option.value}
      </Select.Option>
    )),
  ];

  return (
    <RangeSelectWrapper>
      <SelectContainer>
        <Select
          aria-label={minOptionsList[0].displayName}
          disabled={disabled}
          hasError={hasError}
          onChange={(e) => onChange({ min: e.target.value, max: maxValue })}
          value={minValue}
        >
          {minOptions}
        </Select>
      </SelectContainer>

      <SelectContainer>
        <Select
          aria-label={maxOptionsList[0].displayName}
          disabled={disabled}
          hasError={hasError}
          onChange={(e) => onChange({ min: minValue, max: e.target.value })}
          value={maxValue}
        >
          {maxOptions}
        </Select>
      </SelectContainer>
    </RangeSelectWrapper>
  );
};
