import { useEffect, useState } from 'react';

import {
  SuccessContainer,
  ContentContainer,
  ButtonWrapper,
} from './ErrorContent.styled';
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Illustration,
  Space,
} from '../../atoms';
import { HeaderSizes, HeaderTitle } from '../../molecules';

//Temporary display these variables to ensure they are being passed correctly
const NODE_ENV = process.env.NODE_ENV;
const AGENT_ENDPOINT = process.env.AGENT_ENDPOINT;
const DAFT_API_GATEWAY = process.env.DAFT_API_GATEWAY;
const GOOGLE_TAG_MANAGER = process.env.GOOGLE_TAG_MANAGER;
const KC_CLIENT_ID = process.env.KC_CLIENT_ID;
const KEYCLOAK_ISSUER = process.env.KEYCLOAK_ISSUER;
const MEDIA_URL = process.env.MEDIA_URL;
const NEXT_PUBLIC_AUTH_SERVER_URI = process.env.NEXT_PUBLIC_AUTH_SERVER_URI;
const NEXT_PUBLIC_ENVIRONMENT_NAME = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME;
const NEXT_PUBLIC_KC_REALM = process.env.NEXT_PUBLIC_KC_REALM;
const NEXT_PUBLIC_LEGACY_REDIRECT_URL =
  process.env.NEXT_PUBLIC_LEGACY_REDIRECT_URL;
const NEXTAUTH_URL = process.env.NEXTAUTH_URL;
const NEXT_PUBLIC_RAYGUN_API_KEY = process.env.NEXT_PUBLIC_RAYGUN_API_KEY;
const NEXT_PUBLIC_BASE_CDN_URL = process.env.NEXT_PUBLIC_BASE_CDN_URL;
const constantsMap = {
  NODE_ENV,
  AGENT_ENDPOINT,
  DAFT_API_GATEWAY,
  GOOGLE_TAG_MANAGER,
  KC_CLIENT_ID,
  KEYCLOAK_ISSUER,
  MEDIA_URL,
  NEXT_PUBLIC_AUTH_SERVER_URI,
  NEXT_PUBLIC_ENVIRONMENT_NAME,
  NEXT_PUBLIC_KC_REALM,
  NEXT_PUBLIC_LEGACY_REDIRECT_URL,
  NEXTAUTH_URL,
  NEXT_PUBLIC_RAYGUN_API_KEY,
  NEXT_PUBLIC_BASE_CDN_URL,
};

const variables = Object.entries(constantsMap).map(([key, value]) => {
  return { [key]: value };
});

export const ErrorContent = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [displayVariables, setDisplayVariables] = useState(false);

  useEffect(() => {
    //TODO remove this code after .env is set up
    if (localStorage.getItem('featureFlag_displayVariables') === 'true') {
      setDisplayVariables(true);
    }

    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    if (typeof window !== 'undefined') {
      updateWindowWidth();
      window.addEventListener('resize', updateWindowWidth);
      return () => {
        window.removeEventListener('resize', updateWindowWidth);
      };
    }
  }, []);

  const mobileHeight = 149;
  const desktopHeight = 268;
  const imageHeight = windowWidth < 840 ? mobileHeight : desktopHeight;

  return (
    <>
      <Space space={'XL64'} />
      <SuccessContainer>
        <Illustration illustration={'ERROR'} height={imageHeight} />
        <ContentContainer>
          <HeaderTitle
            headerSizes={HeaderSizes.LARGE}
            setIcon={false}
            text="Something went wrong"
            subtitleText="We can’t find the page you’re looking for. We’re resolving it. Please try again later."
          />
          <Space space={{ sm: 'M32', lg: 'L40' }} />
          <ButtonWrapper>
            <Button
              href="/"
              element="a"
              fullWidth={false}
              buttonType={ButtonTypes.TERTIARY}
              buttonSize={ButtonSizes.MEDIUM}
            >
              Back to Homepage
            </Button>
          </ButtonWrapper>
        </ContentContainer>
      </SuccessContainer>
      <Space space={'XL80'} />

      {displayVariables &&
        variables.map((variable, index) => {
          const [key, value] = Object.entries(variable)[0];
          return (
            <li key={index}>
              <strong>{key}:</strong> {value}
            </li>
          );
        })}
    </>
  );
};
