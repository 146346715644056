import Link from 'next/link';
import { Icon, Space, Text, TextFontWeight, TextSize } from '../../atoms';
import * as Styled from './Pagination.styled';
import { PaginationProps } from './Pagination.types';
import { getPaginationData } from './Pagination.utils';
import { useCheckIsMobile } from './useCheckIsMobile';
import { usePathname, useSearchParams } from 'next/navigation';

const Pagination = ({
  totalPages,
  currentPage,
  totalResults,
  firstResult,
  lastResult,
}: PaginationProps) => {
  const { isMobileState } = useCheckIsMobile();
  const data = getPaginationData(totalPages, currentPage, isMobileState);
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const createPageURL = (pageNumber: number | string) => {
    const params = new URLSearchParams(searchParams);
    params.set('page', pageNumber.toString());
    return `${pathname}?${params.toString()}`;
  };

  return (
    <>
      <Styled.PaginationWrapper aria-label="Pagination Navigation">
        <Styled.PaginationList>
          {currentPage !== 1 && (
            <Styled.PaginationItem>
              <Link
                aria-label="Go to previous page"
                href={createPageURL(currentPage - 1)}
                onClick={(event) => event.currentTarget.blur()}
              >
                <Icon icon="CHEVRONLEFT" size={12} />
              </Link>
            </Styled.PaginationItem>
          )}
          {data.map((page, index) =>
            // Check if the current item is a number, indicating a page number.
            typeof page === 'number' ? (
              <Styled.PaginationItem key={page}>
                <Link
                  aria-label={
                    page === currentPage
                      ? `Current Page ${page}`
                      : `Go to page ${page}`
                  }
                  aria-current={page === currentPage}
                  href={createPageURL(page)}
                >
                  {page.toLocaleString()}
                </Link>
              </Styled.PaginationItem>
            ) : (
              // Render ellipsis for skipped pages (e.g., when the pagination has a gap between numbers).
              <Styled.PaginationItem key={`Ellipsis-${index}`}>
                <Styled.Ellipsis aria-hidden={true}>{page}</Styled.Ellipsis>
              </Styled.PaginationItem>
            ),
          )}

          {currentPage !== totalPages && (
            <Styled.PaginationItem>
              <Link
                aria-label="Go to next page"
                href={createPageURL(currentPage + 1)}
                onClick={(event) => event.currentTarget.blur()}
              >
                <Icon icon="CHEVRONRIGHT" size={12} />
              </Link>
            </Styled.PaginationItem>
          )}
        </Styled.PaginationList>
      </Styled.PaginationWrapper>
      <Space space={{ lg: 'M16' }} />
      <Styled.PaginationWrapper>
        {/* Display a summary of the results being shown in the current view. */}
        <Text size={TextSize.S14} fontWeight={TextFontWeight.REGULAR}>
          {/* e.g. 21-40 */}
          {`${firstResult.toLocaleString()} - ${lastResult.toLocaleString()} ${
            totalResults
              ? `of ${totalResults.toLocaleString()} total results`
              : null
          }`}
        </Text>
      </Styled.PaginationWrapper>
    </>
  );
};

export default Pagination;
